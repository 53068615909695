
import { IonPage, IonContent, } from '@ionic/vue';
import PageHeader from "@/components/PageHeader.vue";
import PageFooter from "@/components/PageFooter.vue";
import AdminMenu from "@/views/Admin/AdminMenu.vue";

import { defineComponent, } from 'vue';

import PageDescription from '@/components/PageDescription.vue';
import TrackingHeatmapComponent from '@/views/Tracking/TrackingHeatmapComponent.vue';

export default defineComponent({
  name: 'TrackingAlertsPage',
  components: { 
    PageHeader,
    PageFooter,
    PageDescription,
    IonPage, 
    IonContent,
    AdminMenu,
    TrackingHeatmapComponent,
  },
  setup () {
    return {
    }
  }
});

