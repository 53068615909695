import { defineStore } from 'pinia'
import { prettyResponse, getNow, patchNow, ogoServices, putNow } from './index';
import { AxiosResponse } from 'axios';
import { ShipmentMetrics, ShipmentTrackingStatus } from '@/models/trackingService/ShipmentMetrics';
import { RemovableRef, StorageSerializers, useLocalStorage } from '@vueuse/core';
import { ShipmentDto, MerchantIssueUpdateDto, OgoIssueUpdateDto, TrackingEventDto, IsLateUpdateDto, OrderTypeEnum } from '@/models/trackingService/Shipment';
import { eventBus } from '@/composables/useOgoEvents';
import { ShipmentMetricsData } from '@/models/trackingService/ShipmentMetricsData';

export class TrackingDashboardState {
  showDeliveryType: boolean;
  showDeliveryState: boolean;
  showAllChannels: boolean;
  showLate: boolean;
  showIssues: boolean;
  sortBy: string;
  sortDesc: boolean;
  orderType: OrderTypeEnum;
  fromDate: string;
  toDate: string;
  warehouseCode: string|undefined;

  removeDays = (date: Date, days: number) => {
    const result = new Date(date)
    result.setDate(result.getDate() - days)
    return result
  }

  constructor() {
    this.showDeliveryType = true;
    this.showDeliveryState = true;
    this.sortBy = "Type";
    this.sortDesc = false;
    this.showAllChannels = false;
    this.showLate = false;
    this.showIssues = false;
    this.orderType = OrderTypeEnum.SalesOrder;
    this.fromDate = this.removeDays(new Date(), 30).toISOString();
    this.toDate = new Date().toISOString();
  }
}

interface TrackingStoreState {
  dashboardState: RemovableRef<TrackingDashboardState>;
}

export const useTrackingStore = defineStore('tracking', {
  state: (): TrackingStoreState => ({
    dashboardState: useLocalStorage("dashboardState", new TrackingDashboardState(), { serializer: StorageSerializers.object,  mergeDefaults: true  }),
  }),

  getters: {
  }, 

  actions: {
    async metricsOfDay(date: Date,channelId: number, orderType: OrderTypeEnum|undefined, warehouse?: string) {

      const params = new URLSearchParams({'date':date.toISOString()});

      if (orderType) params.append('orderType', orderType.toString());
      if (warehouse) params.append('warehouseCode', warehouse);

      try {
          const r = await getNow(`shipments/${channelId}/metrics`,ogoServices.ts, params) as AxiosResponse<Array<ShipmentMetrics>>;
          return r.data;
      } catch (reason) {
          throw (prettyResponse(reason,undefined));
      }
    },
    
    async getShipment(id: string, channelId: number) {      
      try {
          const r = await getNow(`shipments/${channelId}/shipment/${id}`,ogoServices.ts, undefined) as AxiosResponse<ShipmentDto>;
          return r.data;
      } catch (reason) {
          throw (prettyResponse(reason,undefined));
      }
    },

    async getTracking(id: string, channelId: number) {      
      try {
          const r = await getNow(`trackings/${channelId}/tracking/${id}`,ogoServices.ts, undefined) as AxiosResponse<ShipmentDto>;
          return r.data;
      } catch (reason) {
          throw (prettyResponse(reason,undefined));
      }
    },

    async getShipmentAlertMetrics(days: number) {
      const params = new URLSearchParams({'days':days.toString()});
      const r = await getNow(`shipments/alert-metrics`,ogoServices.ts, params) as AxiosResponse<ShipmentMetricsData>;
      return r.data;
    },

    async searchShipments(channelId: number, 
      status: ShipmentTrackingStatus|undefined, 
      deliveryType: string|undefined,
      orderType: OrderTypeEnum|undefined,
      shippedDate: Date ) {
                
      const params = new URLSearchParams({'shippedDate':shippedDate.toISOString()});
      if (status) params.append('status', status.toString());
      if (orderType) params.append('orderType', orderType.toString());
      if (deliveryType) params.append('deliveryType', deliveryType);

      try {
          const r = await getNow(`shipments/${channelId}/search`,ogoServices.ts, params) as AxiosResponse<Array<ShipmentDto>>;
          return r.data;
      } catch (reason) {
          throw (prettyResponse(reason,undefined));
      }
    },

    async saveMerchantIssue(channelId: number, id: string, issue: MerchantIssueUpdateDto){
      try {
        const r = await patchNow(`shipments/${channelId}/shipment/${id}/merchantissue`,ogoServices.ts, issue) as AxiosResponse<ShipmentDto>;
        return r.data;
      } catch (reason) {
        throw (prettyResponse(reason,undefined));
      }
    },

    async saveOgoIssue(channelId: number, id: string, issue: OgoIssueUpdateDto){
      try {
        const r = await patchNow(`shipments/${channelId}/shipment/${id}/ogoissue`,ogoServices.ts, issue) as AxiosResponse<ShipmentDto>;
        return r.data;
      } catch (reason) {
        throw (prettyResponse(reason,undefined));
      }
    },

    async addTrackingEvent(channelId: number, id: string, newEvent: TrackingEventDto){
      // try {
        const r = await putNow(`shipments/${channelId}/shipment/${id}/addevent`,ogoServices.ts, newEvent) as AxiosResponse<ShipmentDto>;
        eventBus.emit('shipmentChanged', r.data);
        return r.data;
      // } catch (reason) {
      //   throw (prettyResponse(reason,undefined));
      // }
    },

    async setIsLate(channelId: number, id: string, isLateModel: IsLateUpdateDto){
      try {
        const r = await putNow(`shipments/${channelId}/shipment/${id}/islate`,ogoServices.ts, isLateModel) as AxiosResponse<ShipmentDto>;
        return r.data;
      } catch (reason) {
        throw (prettyResponse(reason,undefined));
      }
    },

    
  }
})