
import { computed, defineComponent, onMounted, ref, Ref } from 'vue';

import { useErrorBox } from '@/components/errorBox';
import { useTrackingStore } from '@/store/trackingStore';
import * as moment from 'moment-timezone';
import { useAuthStore } from '@/store/authStore';
import { ShipmentMetricsData } from '@/models/trackingService/ShipmentMetricsData';



export default defineComponent({
  name: 'TrackingHeatmapComponent',
  components: { 
  },
  data() {
    return {
      chartOptions: {
        responsive: true
      }
    }
  },
  setup (){    
    const trackingStore = useTrackingStore();
    const auth = useAuthStore();

    const { showError } = useErrorBox();

    const momentjs: any = moment;
    moment.locale(auth.locale);

    const processing = ref(false);
    const adminsRole = computed(()=>auth.filteredRoles.includes("Admins"));

    const data: Ref<ShipmentMetricsData|undefined> = ref(undefined);

    const refreshCharts = async () => {
      if(processing.value) return;

      try {
        processing.value = true;

        data.value = await trackingStore.getShipmentAlertMetrics(30);

      } catch (err) {
        await showError(err, "Error")
      } finally {
        processing.value = false;
      }

    }

    onMounted(async () => {
      await refreshCharts();
    });


  return {
    adminsRole,
    momentjs,
    processing,
    data,
  }
}
});

