import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "page-layout" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_page_header = _resolveComponent("page-header")!
  const _component_PageDescription = _resolveComponent("PageDescription")!
  const _component_AdminMenu = _resolveComponent("AdminMenu")!
  const _component_TrackingHeatmapComponent = _resolveComponent("TrackingHeatmapComponent")!
  const _component_page_footer = _resolveComponent("page-footer")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_ion_page = _resolveComponent("ion-page")!

  return (_openBlock(), _createBlock(_component_ion_page, null, {
    default: _withCtx(() => [
      _createVNode(_component_page_header, { pageName: "Tracking dashboard" }),
      _createVNode(_component_ion_content, { fullscreen: true }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            false
              ? (_openBlock(), _createBlock(_component_PageDescription, {
                  key: 0,
                  pageName: "Tracking dashboard",
                  pageDescription: "See overview of shipments here",
                  breadCrumbs: [{title: 'Admin', active: true, url: '/home'}]
                }))
              : _createCommentVNode("", true),
            _createVNode(_component_AdminMenu),
            _createVNode(_component_TrackingHeatmapComponent),
            _createVNode(_component_page_footer)
          ])
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}